import React, { PureComponent } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Malakas from "../components/malakas"
import { malakes } from "../data"

export default class IndexPage extends PureComponent {
  componentDidMount() {
    // Handle possible hash on URL.
    if (window.location.hash) {
      const node = document.getElementById(window.location.hash.substring(1))
      if (node) node.scrollIntoView()
    }
  }

  render() {
    return (
      <Layout>
        <SEO title="" keywords={`μαλάκες|οδηγοί|φανάρια|πεζοδρόμια`.split(`|`)} />
        {malakes
          .sort((a, b) => b.id - a.id)
          .map((malakas) => (
            <Malakas key={malakas.id} {...malakas} />
          ))}
      </Layout>
    )
  }
}
