import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

export const malakes = [
  {
    id: 1,
    title: "Έργο τέχνης.",
    description:
      "Τέσσερα αμάξια και ένα μηχανάκι αραγμένα βαθιά μέσα σε πεζοδρόμια ράμπες, διάβαση και οδηγό τυφλών, και για bonus, τσουπ, νάτος ο μαλάκας ο παπάκιας ο deliverας ανάποδο ρεύμα. Απλά μπουρδέλο.",
    images: ["IMG_20180201_085038"],
    tags: "παρκάρισμα|μηχανάκι|ράμπα|οδηγός τυφλών|διάβαση|ανάποδο ρεύμα|ΥΧΗ 9219".split("|"),
    date: "2018-02-01",
  },
  {
    id: 2,
    title: "Δεν σκέφτομαι πολλά.",
    description: "Απλά το παρκάρω.",
    images: ["IMG_20180223_084805"],
    tags: "παρκάρισμα|ράμπα|οδηγός τυφλών|διάβαση|ΙΡΥ 5582".split("|"),
    date: "2018-02-23",
  },
  {
    id: 3,
    title: "Τρία αμάξια αράζουν στο πεζοδρόμιο.",
    description: "Δεν τους καίγεται καρφάκι, και αυτό φαίνεται.",
    images: ["IMG_20180223_085855"],
    tags: "παρκάρισμα|πεζοδρόμιο|ράμπα|οδηγός τυφλών|διάβαση|ΖΧΕ 3091".split("|"),
    date: "2018-02-23",
  },
  {
    id: 4,
    title: "Σπρώχνει ποδήλατο.",
    description: '"Χαλαρά, το έχω. Έτσι και αλλιώς δεν θα μου πει κανείς τίποτα."',
    images: ["IMG_20180306_091834"],
    tags: "μηχανάκι|κράνος|ποδήλατο|ΟΒΚ 479".split("|"),
    date: "2018-03-06",
  },
  {
    id: 5,
    title: "Εμπόδιο.",
    description: '"Πετάχτηκα στον μανάβη για 5 λεπτά ρε φίλε, τι θέλεις δηλαδή;"',
    images: ["IMG_20180827_201816"],
    tags: "παρκάρισμα|ράμπα|οδηγός τυφλών|διάβαση".split("|"),
    date: "2018-08-27",
  },
  {
    id: 6,
    title: "Αραχτός στο πεζοδρόμιο έχοντας μπει ανάποδα σε μονόδρομο.",
    description:
      "Και ο από πίσω δεν αφήνει ούτε ένα εκατοστό προσπελάσιμου πεζοδρομίου. Λογικά θα βιαζόντουσαν και οι δύο πάρα πολύ για να κάνουν τις δουλειές τους.",
    images: ["IMG_20180903_191827"],
    tags: "παρκάρισμα|πεζοδρόμιο|ράμπα|ανάποδο ρεύμα|ΑΝΚ 9619".split("|"),
    date: "2018-09-03",
  },
  {
    id: 7,
    title: "Εδώ είναι το σπίτι μου.",
    description: '"Μένω εδώ 15 χρόνια. Θα το παρκάρω όπου θέλω."',
    images: ["IMG_20180903_200224"],
    tags: "παρκάρισμα|πεζοδρόμιο|ΙΒΕ 2998".split("|"),
    date: "2018-09-03",
  },
  {
    id: 8,
    title: "Τέσσερα αμάξια ξεκουράζονται.",
    description: "Πάνω στο πεζοδρόμιο φυσικά. Όσοι χρειάζονται τη ράμπα, ας πάνε από το δρόμο.",
    images: ["IMG_20180904_184043"],
    tags: "παρκάρισμα|πεζοδρόμιο|ράμπα|ΖΥΜ 6035".split("|"),
    date: "2018-09-04",
  },
  {
    id: 9,
    title: "Smart Idiot.",
    description: "Εκεί βρήκε, εκεί το άφησε ο μαλάκας.",
    images: ["IMG_20180904_184122"],
    tags: "παρκάρισμα|πεζοδρόμιο|ποδηλατόδρομος|ΖΧΚ 5495".split("|"),
    date: "2018-09-04",
  },
  {
    id: 10,
    title: "Αρχοντικά αραγμένη στον ποδηλατόδρομο.",
    description: "Άλλη μία BMW η οποία στην προηγούμενη ζωή της ήτανε ποδήλατο.",
    images: ["IMG_20180907_193752"],
    tags: "παρκάρισμα|πεζοδρόμιο|ποδηλατόδρομος|bmw|ΧΝΚ 2329".split("|"),
    date: "2018-09-07",
  },
  {
    id: 11,
    title: "Ο ποδηλατόδρομος μου ανήκει.",
    description: "Επειδή είμαι δίτροχο.",
    images: ["IMG_20180913_091307"],
    tags: "ποδηλατόδρομος|μηχανάκι|ΙΕΜ 547".split("|"),
    date: "2018-09-13",
  },
  {
    id: 12,
    title: '"Και που να το αφήσω ρε φίλε;"',
    description:
      "Κάνε την δουλειά σου και μην σε νοιάζει για τους άλλους. Στο background άλλα 3 αμάξια που έχουν μετατρέψει το πεζοδρόμιο σε parking.",
    images: ["IMG_20181021_130938"],
    tags: "παρκάρισμα|πεζοδρόμιο|ράμπα|οδηγός τυφλών|διάβαση|ΥΕΟ 1505".split("|"),
    date: "2018-10-21",
  },
  {
    id: 13,
    title: "Ο πεζός θα πάει από δίπλα.",
    description:
      "Οι ζωγραφισμένες άσπρες γραμμές και τα πλακάκια με τις τελίτσες πρέπει να είναι κάποια εικαστική παρέμβαση.",
    images: ["IMG_20181106_172448"],
    tags: "παρκάρισμα|πεζοδρόμιο|ράμπα|οδηγός τυφλών|διάβαση|ΙΜΕ 4873".split("|"),
    date: "2018-11-06",
  },
  {
    id: 14,
    title: "Το παρκάρισμα της χρονιάς.",
    description:
      "Έκλεισε και τον καθρέφτη του για το αμάξι που δεν χωράει να περάσει. Άγνωστο τι σκεφτότανε όταν το έκανε αυτό.",
    images: ["IMG_20181114_100827", "IMG_20181114_101245"],
    tags: "παρκάρισμα|παρεμπόδιση κυκλοφορίας|ΙΜΤ 7844".split("|"),
    date: "2018-11-14",
  },
  {
    id: 15,
    title: "Όπως τα ποδήλατα.",
    description: "Έχει κίνηση, ας πάμε από τον ποδηλατόδρομο. Χαζοί είμαστε;",
    images: ["IMG_20181123_085936", "IMG_20181123_085935"],
    tags: "μηχανάκι|ποδηλατόδρομος|ΙΖΜ 883|ΤΖΤ 314".split("|"),
    date: "2018-11-14",
  },
  {
    id: 16,
    title: "Πάνω στη νησίδα καλά είναι.",
    description: "Από το ίδιο σημείο με 2 εβδομάδες διαφορά. Μάλλον το έχει νοικιάσει το σποτ.",
    images: ["IMG_20190124_094240", "IMG_20190206_091713"],
    tags: "παρκάρισμα|νησίδα|ΙΒΕ 8498".split("|"),
    date: "2019-01-24 και 2019-02-06",
  },
  {
    id: 17,
    title: "Δεν θα περάσει κανείς από εκεί.",
    description: "Αλλά και να περάσει κανένας ανάπηρος, τυφλός ή με καρότσι, στα αρχίδια μου.",
    images: ["IMG_20190306_090443"],
    tags: "παρκάρισμα|ράμπα|οδηγός τυφλών|διάβαση|ΥΝΥ 5875|ΖΖΗ 1671".split("|"),
    date: "2019-03-06",
  },
  {
    id: 18,
    title: "Μία BMW κάνει ποδήλατο.",
    description: "Ο ΚΟΚ και η καλή συμπεριφορά είναι κάτι άγνωστο για τους οδηγούς BMW.",
    images: ["IMG_20190319_174455"],
    tags: "παρκάρισμα|ποδηλατόδρομος|bmw|ΖΧΖ 2901".split("|"),
    date: "2019-03-19",
  },
  {
    id: 19,
    title: "Στο πεζοδρόμιο πάνω είναι μιά χαρά.",
    description: "Είναι δύσκολη η κατάσταση σήμερα. Θα το αφήσω εδώ για 10 λεπτά.",
    images: ["IMG_20190402_115326"],
    tags: "παρκάρισμα|πεζοδρόμιο|ΖΧΗ 1073".split("|"),
    date: "2019-04-02",
  },
  {
    id: 20,
    title: "Πρώτα τα αμάξια…",
    description: "…και αν χωράνε οι πεζοί έχει καλώς.",
    images: ["IMG_20190503_181342"],
    tags: "παρκάρισμα|πεζοδρόμιο|ΖΚΒ 1630|ΥΜΧ 5502".split("|"),
    date: "2019-05-03",
  },
  {
    id: 21,
    title: "Μ' όποιον δάσκαλο καθίσεις…",
    description: "…τέτοια οδήγηση θα μάθεις. Και στο background bonus αμάξι καβάλα στη νησίδα.",
    images: ["IMG_20190724_173426"],
    tags: "παρκάρισμα|πεζοδρόμιο|οδηγός τυφλών|νησίδα|ΙΖΖ 4875".split("|"),
    date: "2019-07-24",
  },
  {
    id: 22,
    title: "Η καλή μέρα από το πρωί φαίνεται.",
    description: "Ελπίζω να μην σου χάλασε η διάθεση.",
    images: ["IMG_20190916_085142"],
    tags: "παρκάρισμα|διάβαση|οδηγός τυφλών|ΒΚΑ 3446".split("|"),
    date: "2019-09-16",
  },
  {
    id: 23,
    title: "Είχα δουλειά.",
    description: "Λογικά δεν ενοχλείται κανείς.",
    images: ["IMG_20190918_084237"],
    tags: "παρκάρισμα|διάβαση|ράμπα|οδηγός τυφλών|ΖΥΥ 9848".split("|"),
    date: "2019-09-18",
  },
  {
    id: 24,
    title: "Παρκάρισμα που βρήκα ο μπαγάσας…",
    description: "…κουτί ήρθε.",
    images: ["IMG_20191015_083427"],
    tags: "παρκάρισμα|πεζοδρόμιο|ΙΡΕ 1381".split("|"),
    date: "2019-10-15",
  },
  {
    id: 25,
    title: "Εδώ, μέχρι να ξεφορτώσω.",
    description: "Η δουλειά μου προηγείται.",
    images: ["IMG_20191022_084754"],
    tags: "παρκάρισμα|πεζοδρόμιο|οδηγός τυφλών|ΥΕΑ 5067".split("|"),
    date: "2019-10-22",
  },
  {
    id: 26,
    title: "Το Tesla ξεκουράζεται.",
    description: "Με τέτοιο όχημα, χαλάλι.",
    images: ["IMG_20200618_131416"],
    tags: "παρκάρισμα|πεζοδρόμιο|tesla|ΥΤΡ 4747".split("|"),
    date: "2020-06-18",
  },
  {
    id: 27,
    title: "Όλοι στο πεζοδρόμιο.",
    description: "Δεν έχουμε που αλλού να παρκάρουμε.",
    images: ["IMG_20200924_175918"],
    tags: "παρκάρισμα|πεζοδρόμιο|οδηγός τυφλών|ράμπα|ποδηλατόδρομος|ZBB 3116|IKI 6290".split("|"),
    date: "2020-09-24",
  },
  {
    id: 28,
    title: "Η πιο σίγουρη θέση πάρκινγκ.",
    description: "2 χρόνια εκεί το βάζει.",
    images: ["IMG_20210412_080825"],
    tags: "παρκάρισμα|πεζοδρόμιο|KAN 4026".split("|"),
    date: "2021-04-12",
  },
  {
    id: 29,
    title: "Ούτε έγκυος, ούτε ΑΜΕΑ.",
    description: "Απλά μαλάκας.",
    images: ["IMG_20220203_174624"],
    tags: "παρκάρισμα|έγκυος|αμέα|ΖΚΚ 1075".split("|"),
    date: "2022-02-03",
  },
  {
    id: 30,
    title: "Καλά το πάρκαρες.",
    description: "Έκατσε ωραία.",
    images: ["IMG_20220605_180351"],
    tags: "παρκάρισμα|πεζοδρόμιο|οδηγός τυφλών|ΙΥΤ 3249".split("|"),
    date: "2022-06-05",
  },
  {
    id: 31,
    title: "Αμάξι ποδήλατο.",
    description: "Κάνει και σούζες.",
    images: ["IMG_20220614_173845"],
    tags: "παρκάρισμα|πεζοδρόμιο|ποδηλατόδρομος|YKH 9197".split("|"),
    date: "2022-06-14",
  },
  {
    id: 32,
    title: "Το τέλειο παρκάρισμα.",
    description: "Όχι και τόσο Smart.",
    images: ["IMG_20240107_100801"],
    tags: "παρκάρισμα|πεζοδρόμιο|οδηγός τυφλών|YNY 3759".split("|"),
    date: "2024-01-07",
  },
  {
    id: 33,
    title: "Μια χαρά.",
    description: "Καλά είναι εδώ.",
    images: ["IMG_20240110_113220"],
    tags: "παρκάρισμα|πεζοδρόμιο|οδηγός τυφλών|IEE 5598".split("|"),
    date: "2022-06-10",
  },
]

const imagesQuery = graphql`
  fragment servicesImage on File {
    childImageSharp {
      fluid(maxWidth: 1024, quality: 80) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  # prettier-ignore
  query {
    IMG_20180201_085038: file(relativePath: { eq: "IMG_20180201_085038.jpg" }) {...servicesImage}
    IMG_20180223_084805: file(relativePath: { eq: "IMG_20180223_084805.jpg" }) {...servicesImage}
    IMG_20180223_085855: file(relativePath: { eq: "IMG_20180223_085855.jpg" }) {...servicesImage}
    IMG_20180306_091834: file(relativePath: { eq: "IMG_20180306_091834.jpg" }) {...servicesImage}
    IMG_20180827_201816: file(relativePath: { eq: "IMG_20180827_201816.jpg" }) {...servicesImage}
    IMG_20180903_191827: file(relativePath: { eq: "IMG_20180903_191827.jpg" }) {...servicesImage}
    IMG_20180903_200224: file(relativePath: { eq: "IMG_20180903_200224.jpg" }) {...servicesImage}
    IMG_20180904_184043: file(relativePath: { eq: "IMG_20180904_184043.jpg" }) {...servicesImage}
    IMG_20180904_184122: file(relativePath: { eq: "IMG_20180904_184122.jpg" }) {...servicesImage}
    IMG_20180907_193752: file(relativePath: { eq: "IMG_20180907_193752.jpg" }) {...servicesImage}
    IMG_20180913_091307: file(relativePath: { eq: "IMG_20180913_091307.jpg" }) {...servicesImage}
    IMG_20181021_130938: file(relativePath: { eq: "IMG_20181021_130938.jpg" }) {...servicesImage}
    IMG_20181106_172448: file(relativePath: { eq: "IMG_20181106_172448.jpg" }) {...servicesImage}
    IMG_20181114_100827: file(relativePath: { eq: "IMG_20181114_100827.jpg" }) {...servicesImage}
    IMG_20181114_101245: file(relativePath: { eq: "IMG_20181114_101245.jpg" }) {...servicesImage}
    IMG_20181123_085935: file(relativePath: { eq: "IMG_20181123_085935.jpg" }) {...servicesImage}
    IMG_20181123_085936: file(relativePath: { eq: "IMG_20181123_085936.jpg" }) {...servicesImage}
    IMG_20190124_094240: file(relativePath: { eq: "IMG_20190124_094240.jpg" }) {...servicesImage}
    IMG_20190206_091713: file(relativePath: { eq: "IMG_20190206_091713.jpg" }) {...servicesImage}
    IMG_20190306_090443: file(relativePath: { eq: "IMG_20190306_090443.jpg" }) {...servicesImage}
    IMG_20190319_174455: file(relativePath: { eq: "IMG_20190319_174455.jpg" }) {...servicesImage}
    IMG_20190402_115326: file(relativePath: { eq: "IMG_20190402_115326.jpg" }) {...servicesImage}
    IMG_20190503_181342: file(relativePath: { eq: "IMG_20190503_181342.jpg" }) {...servicesImage}
    IMG_20190724_173426: file(relativePath: { eq: "IMG_20190724_173426.jpg" }) {...servicesImage}
    IMG_20190916_085142: file(relativePath: { eq: "IMG_20190916_085142.jpg" }) {...servicesImage}
    IMG_20190918_084237: file(relativePath: { eq: "IMG_20190918_084237.jpg" }) {...servicesImage}
    IMG_20191015_083427: file(relativePath: { eq: "IMG_20191015_083427.jpg" }) {...servicesImage}
    IMG_20191022_084754: file(relativePath: { eq: "IMG_20191022_084754.jpg" }) {...servicesImage}
    IMG_20200618_131416: file(relativePath: { eq: "IMG_20200618_131416.jpg" }) {...servicesImage}
    IMG_20200924_175918: file(relativePath: { eq: "IMG_20200924_175918.jpg" }) {...servicesImage}
    IMG_20210412_080825: file(relativePath: { eq: "IMG_20210412_080825.jpg" }) {...servicesImage}
    IMG_20220203_174624: file(relativePath: { eq: "IMG_20220203_174624.jpg" }) {...servicesImage}
    IMG_20220605_180351: file(relativePath: { eq: "IMG_20220605_180351.jpg" }) {...servicesImage}
    IMG_20220614_173845: file(relativePath: { eq: "IMG_20220614_173845.jpg" }) {...servicesImage}
    IMG_20240107_100801: file(relativePath: { eq: "IMG_20240107_100801.jpg" }) {...servicesImage}
    IMG_20240110_113220: file(relativePath: { eq: "IMG_20240110_113220.jpg" }) {...servicesImage}
  }
`

export const renderMalakasImage = (imageId, index) => (
  <StaticQuery key={index} query={imagesQuery} render={(data) => <Img fluid={data[imageId].childImageSharp.fluid} />} />
)
